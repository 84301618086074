export const sanitizeInput = (body: unknown) => {
  if (typeof body === 'string') {
    // Remove all HTML tags
    return body.replace(/<\/?[^>]+(>|$)/g, '');
  }

  if (typeof body === 'object' && body !== null) {
    return JSON.parse(
      JSON.stringify(body, (_, value) => {
        if (typeof value === 'string') {
          return value.replace(/<\/?[^>]+(>|$)/g, '');
        }

        return value;
      })
    );
  }

  return body;
};
