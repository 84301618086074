import { sanitizeInput } from './api-integration';
import axios from 'axios';

axios.interceptors.request.use(
  (config) => {
    if (config.data) {
      // eslint-disable-next-line no-param-reassign
      config.data = sanitizeInput(config.data);
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default axios;
